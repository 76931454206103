<template>
  <b-modal
    id="question-settings-modal"
    :ok-title="$t('general.save')"
    :title="$t('tests.question_settings')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    @ok.prevent="onSaveQuestionSettings"
    @show="onShowSettingsModal"
  >
    <b-row>
      <!-- <b-col md="12">
        <b-form-group>
          <b-form-checkbox v-model="options.answers_order_matters">
            {{ $t('tests.questions_order_matters') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
      >
        <b-form-group :label="$t('tests.equal_points')">
          <b-form-checkbox
            v-model="options.equal_points"
          >
            {{ $t('tests.equal_points') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col> -->
      <!--
        v-if="options.equal_points" -->
      <b-col
        md="12"
      >
        <b-form-group label="point">
          <b-form-input
            v-model="options.points"
            type="number"
          />
        </b-form-group>
      </b-col>
      <!-- <b-col v-else>

      </b-col> -->
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup,
  // BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    // BFormCheckbox,
    BFormInput,
  },
  props: {
    defOptions: {
      type: Object,
      required: false,
      default() {
        return {
          answers_order_matters: true,
          equal_points: true,
          points: null,
        }
      },
    },
    defPoints: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      options: {
        answers_order_matters: true,
        equal_points: true,
        points: null,
      },
    }
  },
  methods: {
    onShowSettingsModal() {
      this.options.points = this.defPoints
    },
    onSaveQuestionSettings() {
      this.$emit('onSaveQuestionSettings', this.options.points)
    },
  },
}
</script>

<style>

</style>
