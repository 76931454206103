<template>
  <div :key="mounted_key">
    <div class="d-flex justify-content-between align-items-center">
      <b-form-group
        :label="$t('general.title')"
        class="col-lg-4 pl-0"
      >
        <b-form-input v-model="question.title" />
      </b-form-group>
      <b-button
        v-if="['s', 'm'].includes(question.type)"
        variant="primary"
        class="btn-icon mt-1"
        @click="openFormSelectModal"
      >
        <feather-icon icon="SettingsIcon" />
      </b-button>
    </div>
    <b-form-group :label="$t('tests.test_conditions') + ':'">
      <doc-editor v-model="question.condition" />
    </b-form-group>
    <hr>
    <b-card
      v-for="(q_item, index) in question.body"
      :key="index"
    >
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">
          {{ index + 1 }}.
        </h3>

        <div>
          <b-button
            v-if="!['g'].includes(question.type)"
            class="p-50 mr-50"
            @click="openQuestionSettingsModal(index)"
          >
            <feather-icon icon="SlidersIcon" />
          </b-button>
          <b-button
            v-if="question.type !== 'g'"
            variant="danger"
            size="sm"
            class="p-50"
            @click="removeItem(index)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
      </div>
      <hr>
      <b-form-group :label="$t('tests.test_conditions') + ':'">
        <doc-editor v-model="q_item.task" />
      </b-form-group>
      <b-form-group
        v-if="['s', 'm', 'a'].includes(question.type)"
        id="test-base-question"
        :label="$t('tests.answer_options') + ':'"
      >
        <!-- <b-form-radio
          v-for="(answer_option, ind) in q_item.options"
          :key="ind"
          v-model="question.answer[index]"
          :value="answer_option.key"
          class="mt-1"
          @input="markCorrectAnswer(index, ind)"
        >
          <b-form-input
            v-model="answer_option.content"
          />
          <b-button
            variant="danger"
            class="ml-1"
            @click="removeOption(index, ind)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-form-radio> -->
        <component
          :is="getTypeComponent"
          v-model="question.answer[index]"
          stacked
        >
          <template v-if="question.type === 's' || question.type === 'a'">
            <single-choice-type-form
              v-for="(answer_option, ind) in q_item.options"
              :key="ind"
              :option="answer_option"
              @removeOption="removeOption(index, ind)"
            />
          </template>
          <template v-else-if="question.type === 'm'">
            <multiple-choice-type-form
              v-for="(answer_option, ind) in q_item.options"
              :key="ind"
              :option="answer_option"
              @removeOption="removeOption(index, ind)"
            />
          </template>
        </component>
        <b-button
          class="mt-1"
          variant="success"
          @click="addNewOption(index, q_item.options.length)"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t("tests.add_answer_option") }}
        </b-button>
      </b-form-group>
      <b-form-group
        v-else-if="question.type === 'g'"
        :label="$t('tests.answers') + ':'"
      >
        <!-- <div
          v-for="(answer, idx) in question.answer"
          :key="idx"
          class="d-flex mt-1"
        >
          <b-form-tags
            v-model="question.answer[idx]"
          />
          <b-button
            class="ml-1"
            variant="danger"
            @click="removeAnswerOptions(idx)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div> -->
        <gap-filling-type-form
          v-for="(answer, idx) in question.answer"
          :key="idx"
          v-model="question.answer[idx]"
          @removeClick="removeAnswerOptions(idx)"
          @openQuestionSettingsModal="openGapFillingSettingsModal(index, idx)"
        />
        <b-button
          class="mt-2"
          variant="success"
          @click="addCorrectAnswer"
        >
          {{ $t("tests.add_correct_answer") }}
        </b-button>
      </b-form-group>
    </b-card>
    <b-button
      v-if="question.type !== 'g'"
      variant="warning"
      @click="addNewQuestion"
    >
      <feather-icon icon="PlusIcon" />
      {{ $t("tests.add_new_question") }}
    </b-button>
    <hr>
    <div class="mt-1 d-flex justify-content-end">
      <b-button
        variant="secondary"
        class="mr-1"
        @click="preview"
      >
        {{ $t("tests.preview") }}
      </b-button>
      <b-button
        variant="primary"
        @click="saveClick"
      >
        {{ $t("general.save") }}
      </b-button>
    </div>
    <question-settings-modal
      :def-points="defPoints"
      @onSaveQuestionSettings="onSaveQuestionSettings"
    />
    <b-sidebar
      :key="increment"
      v-model="preview_sidebar"
      width="100vw"
    >
      <test-preview
        :sidebar="preview_sidebar"
        :questions-body="question"
      />
      <test-form-select-modal
        :edit-data="editData"
        @save="FormSelected"
      />
    </b-sidebar>
  </div>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCard,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BSidebar,
  BInputGroup,
  BFormTags,
} from 'bootstrap-vue'
import { mapActions, mapMutations } from 'vuex'
import docEditor from '@/components/documentCKEditor.vue'
import singleChoiceTypeForm from './components/singleChoiceTypeForm.vue'
import multipleChoiceTypeForm from './components/multipleChoiceTypeForm.vue'
import gapFillingTypeView from './components/gapFillingTypeView.vue'
import gapFillingTypeForm from './components/gapFillingTypeForm.vue'
import testPreview from './components/testPreview.vue'
import dynamic from './components/dynamic.vue'
import testFormSelectModal from './components/modals/testFormSelectModal.vue'
import questionSettingsModal from './components/modals/questionSettingsModal.vue'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    testFormSelectModal,
    // BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BSidebar,
    singleChoiceTypeForm,
    multipleChoiceTypeForm,
    gapFillingTypeView,
    testPreview,
    dynamic,
    BInputGroup,
    BFormTags,
    gapFillingTypeForm,
    docEditor,
    questionSettingsModal,
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      increment: 999,
      preview_sidebar: false,
      questionSettingsIndex: null,
      defPoints: null,
      g_point_index: null,
      question: {
        is_vertical: true,
        title: '',
        condition: '',
        variant_type: '',
        body: [
          {
            task: '',
            options: [],
            g_score: [],
            score: null,
            id: 1,
          },
        ],
        answer: [],
        type: this.$route.params.type,
        folder: Number(this.$route.params.folder_id) || null,
      },
      editData: {
        is_vertical: true,
        variant_type: 'none',
      },
      mounted_key: 1,
    }
  },
  computed: {
    getTypeComponent() {
      // console.log(this.$data.$_test_type_options.find(el => el.value === this.$route.params.type).component)
      return this.$data.$_test_type_options.find(
        el => el.value === this.question.type,
      ).component
    },
    getQuestionsCount() {
      if (this.question.type === 'g') {
        return this.question.body[0].task.match(/@question_gap/g)
      }
      return []
    },
  },
  mounted() {
    if (this.isEdit) {
      this.FETCH_ONE_QUESTION(this.$route.params.id).then(resp => {
        this.question = resp
        this.updateBreadcrumb()
        this.mounted_key += 1
      })
    }
  },
  methods: {
    ...mapActions('questions', [
      'ADD_QUESTION',
      'EDIT_QUESTION',
      'FETCH_ONE_QUESTION',
    ]),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    addNewOption(index, incr) {
      this.question.body[index].options.push({ key: incr + 1, content: '' })
    },
    openFormSelectModal() {
      this.$set(this.editData, 'variant_type', this.question.variant_type)
      this.$set(this.editData, 'is_vertical', this.question.is_vertical)
      this.$nextTick(() => {
        this.$bvModal.show('test-form-select-modal')
      })
    },
    FormSelected(value) {
      this.question.is_vertical = value.is_vertical
      this.question.variant_type = value.variant_type
    },
    openQuestionSettingsModal(index) {
      this.questionSettingsIndex = index
      this.defPoints = this.question.body[index].score
      this.$nextTick(() => {
        this.$bvModal.show('question-settings-modal')
      })
    },
    openGapFillingSettingsModal(index, idx) {
      this.questionSettingsIndex = index
      if (this.question.body[index].g_score) {
        this.defPoints = this.question.body[index].g_score[idx]
      } else {
        this.question.body[index].g_score = []
        this.defPoints = this.question.body[index].g_score[idx]
      }
      this.g_point_index = idx
      this.$nextTick(() => {
        this.$bvModal.show('question-settings-modal')
      })
    },
    onSaveQuestionSettings(value) {
      if (this.question.type === 'g') {
        this.$set(this.question.body[this.questionSettingsIndex].g_score, this.g_point_index, value)
      } else {
        this.question.body[this.questionSettingsIndex].score = value
      }
      this.$nextTick(() => {
        this.$bvModal.hide('question-settings-modal')
      })
    },
    updateBreadcrumb() {
      const [first, second, third] = this.$_removeObjectConnection(
        this.$route.meta.breadcrumb,
      )
      second.text = this.question.title
      this.UPDATE_BREADCRUMB([first, second, third])
    },
    removeOption(index, ind) {
      this.question.body[index].options.splice(ind, 1)
      this.$forceUpdate()
    },
    removeItem(index) {
      this.question.body.splice(index, 1)
      this.question.answer.splice(index, 1)
    },
    addNewQuestion() {
      this.question.body.push({
        task: '',
        options: [],
        g_score: [],
        score: null,
        id: this.question.body.length + 1,
      })
    },
    addCorrectAnswer() {
      this.question.answer.push('')
    },
    saveClick() {
      const type = ['s', 'm', 'a']
      if (type.includes(this.question.type)) {
        if (this.question.answer.length !== this.question.body.length) {
          this.$_errorToast(this.$t('general.no_valid'))
          return
        }
      }
      if (this.question.type === 'g') {
        console.log(this.question)
        let isValid = true
        const replaced = this.question.body[0].task.match(/@question_gap/g)
        if (this.question.answer.length !== replaced?.length) {
          this.$_errorToast(this.$t('general.no_valid'))
          return
        }
        if (this.question.answer.length === replaced?.length) {
          this.question.answer.forEach(el => {
            console.log(el)
            if (!el.length) {
              isValid = false
            }
          })
          if (!isValid) {
            this.$_errorToast(this.$t('general.no_valid'))
            return
          }
        }
      }
      if (this.isEdit) {
        this.EDIT_QUESTION(this.question).then(() => {
          this.$_successToast(this.$t('general.edited'))
          this.$router.push({ name: 'test-base' })
        })
      } else {
        this.ADD_QUESTION(this.question).then(() => {
          this.$_successToast(this.$t('general.added'))
          this.$router.push({ name: 'test-base' })
        })
      }
    },
    preview() {
      this.increment = 1000
      this.preview_sidebar = true
    },
    removeAnswerOptions(index) {
      this.question.answer.splice(index, 1)
    },
    // markCorrectAnswer(index, ind) {
    //   // this.question.body[index].options[ind].is_correct = true
    // },
  },
}
</script>

<style lang="scss">
#test-base-question .custom-control-label {
  display: flex !important;
}
</style>
