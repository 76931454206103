<template>
  <b-modal
    id="test-form-select-modal"
    :title="$t('tests.select_form_of_test')"
    :ok-title="$t('general.select')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    @ok.prevent="saveClick"
    @show="onShow"
  >
    <validation-observer ref="typeValidations">
      <b-form-group :label="$t('tests.test_form') + ':'">
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('tests.test_form')"
        >
          <v-select
            v-model="options.is_vertical"
            :options="statusOptions"
            :get-option-label="(opt) => opt.title[$i18n.locale]"
            :reduce="(opt) => opt.value"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group :label="$t('tests.question_types') + ':'">
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('tests.question_types')"
        >
          <v-select
            v-model="options.variant_type"
            :options="listStyleTypes"
            label="name"
            :reduce="data => data.value"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    editData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statusOptions: [
        {
          value: false,
          title: {
            ru: 'В виде ряда',
            uz: 'Qator shaklida',
            en: 'In the form of a row',
          },
        },
        {
          value: true,
          title: {
            ru: 'В виде колонны',
            uz: 'Ustun shaklida',
            en: 'In the form of a column',
          },
        },
      ],
      listStyleTypes: [
        { name: 'I,II,III', value: 'upper-roman' },
        { name: 'a,b,c', value: 'lower-alpha' },
        { name: 'None', value: 'none' },
      ],
      required,
      options: {
        is_vertical: true,
        variant_type: 'none',
      },
    }
  },
  methods: {
    onShow() {
      this.options = this.$_removeObjectConnection(this.editData)
    },
    async saveClick() {
      const success = await this.$refs.typeValidations.validate()
      if (!success) return
      this.$emit('save', this.options)
      this.$bvModal.hide('test-form-select-modal')
    },
  },
}
</script>

<style>
</style>
