<template>
  <div class="d-flex align-items-center mt-1">
    <div>
      <b-button
        class="p-50 mr-50"
        @click="$emit('openQuestionSettingsModal')"
      >
        <feather-icon icon="SlidersIcon" />
      </b-button>
    </div>
    <b-form-tags
      :value="value"
      @input="$emit('input', $event)"
    />
    <b-button
      class="ml-1"
      variant="danger"
      @click="$emit('removeClick')"
    >
      <feather-icon icon="XIcon" />
    </b-button>
  </div>
</template>

<script>
import { BFormTags, BButton } from 'bootstrap-vue'

export default {
  components: {
    BFormTags,
    BButton,
  },
  props: {
    value: {
      type: null,
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
}
</script>

<style>

</style>
