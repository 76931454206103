<template><div class="d-flex mt-2">
  <b-form-radio
    :value="option.key"
    class="mt-1"
  />
  <div class="col-lg-11">

    <doc-editor
      v-model="option.content"
      @input="$emit('contentChange', option.content)"
    />
  </div>

  <b-button
    variant="danger"
    class="ml-1"
    @click="$emit('removeOption')"
  >
    <feather-icon icon="XIcon" />
  </b-button>
</div>
</template>

<script>
import { BFormRadio, BButton } from 'bootstrap-vue'
import docEditor from '@/components/documentCKEditor.vue'

export default {
  components: {
    BFormRadio,
    BButton,
    docEditor,
  },
  props: {
    checked: {
      type: null,
      required: false,
    },
    option: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      check: this.checked,
      selected: null,
    }
  },
}
</script>

<style>
</style>
